.select-wrapper {
    font-family: Calibre, sans-serif;

    display: flex;
    align-content: center;
    justify-content: center;

    background-color: transparent;

    // Toggle input and span on activation.
    .react-dropdown-select-content {
        input {
            display: none;
        }
    }

    &--active {
        .react-dropdown-select-content {
            input {
                display: inline-block;
            }
            span {
                display: none;
            }
        }
    }
}

$select-width: 250px;
$select-width-large: 500px;

.select {
    margin: 20px 0px 20px 20px;
    height: $select-height;
    font-size: 1em;

    height: 40px;
    font-size: 20px;

    border: 2px solid $color__grey--border !important;
    border-radius: 0 !important;

    background-color: $color__white;

    padding-top: 10px;

    width: $select-width !important;

    @media screen and (min-width: 700px) {
        width: $select-width-large !important;
    }
}

@mixin getFont() {
    font-family: Calibre,sans-serif;
    font-weight: 200;
    font-size: 18px;
}

.react-dropdown-select {
    &:focus-within {
        box-shadow: none !important;
    }

    .react-dropdown-select-content {
        padding-left: 5px;
        width: calc(100% - 50px);

        span {
            padding-left: 3px;
            text-transform: uppercase;

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .react-dropdown-select-input {
        @include getFont();

        // margin-left: 12px;
        margin-top: 0px;

        width: 100%;
    }

    .react-dropdown-select-dropdown {
        border: 1px solid $color__blue;
        width: calc(#{ $select-width } + 13px) !important;
        border-radius: 0 !important;
        left: -3px;

        top: 55px;
        box-shadow: none;

        scroll-snap-type: y mandatory; // See select-item for scroll-snap.

        width: calc(#{ $select-width } + 13px) !important;

        @media screen and (min-width: 700px) {
            width: calc(#{ $select-width-large } + 13px) !important;
        }

        .react-dropdown-select-item {
            @include getFont();
            text-transform: uppercase;

            padding-left: 15px;

            color: $color__grey--background;

            // From barnes www site.
            letter-spacing: .04em;
            line-height: 1.5625;

            scroll-snap-align: start;

            // On select on hover, turn blue.
            &[aria-selected=true],
            &:hover {
                color: $color__white;
                background-color: $color__blue;
            }
        }
    }

    // Rescale SVG.
    .react-dropdown-select-dropdown-handle {
        transform: rotate(180deg) !important;
        margin-right: 5px;
        margin-bottom: 1px;

        $svg-size: 20px;
        svg {
            width: $svg-size;
            height: $svg-size;
        }
    }
}
