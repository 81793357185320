.viewer-controls {
    display: flex;
    justify-content: left;

    width: 100%;
    
    opacity: 0;

    transition: opacity 0.5s ease;

    &--active {
        opacity: 1;
    }

    &__select {
        display: inline-block;
    }
}