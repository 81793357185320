$button-height: 32px;

.home-button {
    $self: &;

    height: calc(#{ $select-height } + 8px);
    // height: $button-height;
    // margin-top: calc(#{ $select-height } + 3px - (#{ $button-height } / 2 ));

    margin: 20px 0 0 20px;

    background-color: $color__white;
    border: 2px solid $color__grey--border;


    text-decoration: none;

    &:focus {
        outline: none;
    }

    &:hover {
        cursor: pointer;

        // border-color: $color__red--barnes;

        #{ $self }__icon {
            color: $color__red--barnes
        }
    }

    &__icon {
        height: 100%;
        color: $color__grey--secondary;
    }
}